import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import EventEmitter from 'events';

export const firebaseProductionConfig = {
  apiKey: "AIzaSyAVPo-5vc3i8Heb5t28c34Rc_F-SmiEo8Q",
  authDomain: "vinama-production-f6ac2.firebaseapp.com",
  projectId: "vinama-production-f6ac2",
  storageBucket: "vinama-production-f6ac2.appspot.com",
  messagingSenderId: "1019629730133",
  appId: "1:1019629730133:web:3dd15d2f5395ec765e17dc",
  measurementId: "G-ZYZT9C6SYK",
}
export const firebaseStatingConfig = {
  apiKey: "AIzaSyCdDCwADdh0BXvksuXkxbluoCnNKsw1Q68",
  authDomain: "vinama-staging-7a13a.firebaseapp.com",
  projectId: "vinama-staging-7a13a",
  storageBucket: "vinama-staging-7a13a.appspot.com",
  messagingSenderId: "123859233191",
  appId: "1:123859233191:web:6665d333ac80e88862ee82",
  measurementId: "G-9ZPE6HXCKW"
};

// Initialize Firebase
const app = initializeApp(process.env.REACT_APP_ENV === 'production' ? firebaseProductionConfig : firebaseStatingConfig)
const messaging = getMessaging(app)

export const getMessagingToken = async () => {
  let currentToken = ""
  if (!messaging) return
  try {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
      })
      return currentToken
    }
  } catch (error) {
    console.log(error)
  }

  return currentToken
}

export const messageEmitter = new EventEmitter();
onMessage(messaging, (payload) => {
  messageEmitter.emit('message', payload)
})

