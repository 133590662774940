import find from "lodash/find"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { SIDEBAR_ITEMS } from "constants/SidebarItems"
import SidebarItem from "./SidebarItem"

const Sidebar = () => {
  let location = useLocation()
  let navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const staffRoles = useSelector((state) => state.user.staffRoles)

  const accessSidebarItems = useMemo(() => {
    if (!staffRoles) return [SIDEBAR_ITEMS[0]]
    const roleData = find(staffRoles, (role) => role.id === currentUser?.staffRoleID)
    if (!roleData) return [SIDEBAR_ITEMS[0]]

    return SIDEBAR_ITEMS.filter(
      (item) => item.id === "dashboard" || roleData.permissions?.includes(item.id)
    )
  }, [currentUser?.staffRoleID, staffRoles])

  useEffect(() => {
    if (accessSidebarItems && location?.pathname) {
      const activeItem = SIDEBAR_ITEMS.find((item) =>
        item.url === "/" ? location?.pathname === "/" : location?.pathname?.startsWith(item.url)
      )
      if (activeItem && !accessSidebarItems?.find((item) => item.id === activeItem.id)) {
        navigate("/")
      }
    }
  }, [accessSidebarItems, location?.pathname, navigate])

  return (
    <div className="w-sidebarWidth flex flex-col max-h-screen">
      <div className="py-8">
        <img src="/images/logo.svg" alt="logo" className="m-auto" />
      </div>
      <div className="space-y-8 flex-1 overflow-auto">
        {accessSidebarItems?.map((item) => (
          <SidebarItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}

export default Sidebar
