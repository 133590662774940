export const ORDER_STATUS = {
  COMPLETED: "done",
  CANCELLED: "canceled",
  CONFIRMING: "confirming"
}

export const ORDER_STATUS_TITLE = {
  [ORDER_STATUS.COMPLETED]: "Completed",
  [ORDER_STATUS.CANCELLED]: "Canceled",
}

export const PAYMENT_STATUS = {
  PAID: "paid",
  UNPAID: "unpaid",
  PARTIAL_PAID: "partial_paid",
}

export const PAYMENT_STATUS_TITLE = {
  [PAYMENT_STATUS.PAID]: "Paid",
  [PAYMENT_STATUS.UNPAID]: "Unpaid",
  [PAYMENT_STATUS.PARTIAL_PAID]: "Partial paid",
}
