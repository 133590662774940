import { combineReducers } from "redux"

import { tableReducer } from "slice/tableSlice"
import { userReducer } from "slice/userSlice"
import { commonReducer } from "slice/commonSlice"
import { notificationReducer } from "slice/notificationSlice"
import { orderReducer } from "slice/orderSlice"
import { transactionReducer } from "slice/transactionSlice"
import { languageReducer } from "slice/languageSlice"
import { messageReducer } from "slice/messageSlice"

export default combineReducers({
  table: tableReducer,
  user: userReducer,
  common: commonReducer,
  notification: notificationReducer,
  order: orderReducer,
  transaction: transactionReducer,
  language: languageReducer,
  message: messageReducer,
})
