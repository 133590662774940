import { createSlice } from '@reduxjs/toolkit'

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    newMessengerPayload: null,
  },
  reducers: {
    setNewMessengerPayload: (state, action) => {
      state.newMessengerPayload = action.payload
    },
  },
})

export const {
  reducer: messageReducer,
  actions: { setNewMessengerPayload },
} = messageSlice
