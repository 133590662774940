import axios from "../axios"

export const getCampaignList = (params) => {
  return axios.get(`/campaigns/`, { params })
}


export const updateCampaign = (campaignId, payload) => {
  return axios.put(`/campaigns/${campaignId}`, payload)
}

export const getCampaignById = (campaignId) => {
  return axios.get(`/campaigns/${campaignId}`)
}


export const updateCampaignStatus = (campaignId, payload) => {
  return axios.put(`/campaigns/${campaignId}/status`, payload)
}

export const updateProductStatus = (campaignId, productId, payload) => {
  return axios.put(`/campaigns/${campaignId}/products/${productId}/status`, payload)
}

export const uploadAcceptanceImages = (campaignId, productId, payload) => {
  return axios.post(`/campaigns/${campaignId}/products/${productId}/acceptanceImages`, payload)
}

export const finishUploadAcceptanceImages = (campaignId, productId, acceptanceImagesId) => {
  return axios.post(`/campaigns/${campaignId}/products/${productId}/acceptanceImages/${acceptanceImagesId}`)
}

export const deleteAcceptanceImages = (campaignId, productId, acceptanceImagesId) => {
  return axios.delete(`/campaigns/${campaignId}/products/${productId}/acceptanceImages/${acceptanceImagesId}`)
}

export const updateProductPerformance = (campaignId, productId, payload) => {
  return axios.put(`/campaigns/${campaignId}/products/${productId}/performance`, payload)
}

export const updateCampaignPrintingContentStatus = (campaignId, payload) => {
  return axios.patch(`/campaigns/${campaignId}/printingContent`, payload)
}
export const getBillingSummary = (campaignId) => {
  return axios.post(`/campaigns/${campaignId}/billingSummary`)
}

// export const createCampaign = (payload) => {
//   return axios.post(`/campaigns/`, payload)
// }

// export const deleteCampaign = (campaignId) => {
//   return axios.delete(`/campaigns/${campaignId}`)
// }
