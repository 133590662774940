import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"
import { useTranslation } from "react-i18next"
import "react-toastify/dist/ReactToastify.css"

import AuthLayout from "layouts/AuthLayout"
import MainLayout from "layouts/MainLayout"
import AuthRoutes from "router/AuthRoutes"
import MainRoutes from "router/MainRoutes"
import { getMessagingToken, messageEmitter } from "./firebase"
import { setFcmToken, setNumberOfUnverifiedAccount, setStaffRoles } from "slice/userSlice"
import { getListUsers, updateProfile } from "services/api/users"
import { resetPageIndex } from "slice/tableSlice"
import { CAMPAIGN_STATUS } from "constants/Status"
import { setNumberOfConfirmingOrder, setNumberOfWaitingCampaign } from "slice/orderSlice"
import { getCampaignList } from "services/api/campaign"
import { getListRoles } from "services/api/roles"
import { getListTransactions } from "./services/api/transaction"
import { TRANSACTION_STATUS } from "./constants/Transaction"
import { setNumberOfPendingTransaction } from "./slice/transactionSlice"
import { getListOrders } from "./services/api/order"
import { ORDER_STATUS } from "./constants/Order"
import "./translations"
import { setNewMessengerPayload } from "./slice/messageSlice"

dayjs.extend(localeData)

function App() {
  const {i18n} = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user.currentUser)
  const fcmToken = useSelector((state) => state.user.fcmToken)

  const countWaitingCampaign = useCallback(async () => {
    try {
      const res = await getCampaignList({page: 1, perPage: 1, status: CAMPAIGN_STATUS.WAITING})
      dispatch(setNumberOfWaitingCampaign(res.data.total))
    } catch (error) {
    }
  }, [dispatch])

  const countPendingTransaction = useCallback(async () => {
    try {
      const res = await getListTransactions({
        page: 1,
        perPage: 1,
        status: TRANSACTION_STATUS.PENDING,
      })
      dispatch(setNumberOfPendingTransaction(res.data.total))
    } catch (error) {
    }
  }, [dispatch])

  const countConfirmingOrder = useCallback(async () => {
    try {
      const res = await getListOrders({page: 1, perPage: 1, status: ORDER_STATUS.CONFIRMING})
      dispatch(setNumberOfConfirmingOrder(res.data.total))
    } catch (error) {
    }
  }, [dispatch])

  const countNumberOfUnverifiedAccount = useCallback(async () => {
    try {
      const res = await getListUsers({page: 1, perPage: 1, role: "user", userType: "corporate"})
      dispatch(setNumberOfUnverifiedAccount(res.data.total))
    } catch (error) {
    }
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      if (currentUser) {
        const res = await getListRoles()
        if (res?.data) {
          dispatch(setStaffRoles(res?.data))
        }
      }
    })()
  }, [currentUser, dispatch])

  useEffect(() => {
    ;(async () => {
      const token = await getMessagingToken()
      if (token) {
        dispatch(setFcmToken(token))
      }
      const channel = new BroadcastChannel("notifications")
      channel.addEventListener("message", (event) => {
        const notification = new Notification(event.data.notification.title, {
          icon: "https://cms.vinama.asia/favicon/android-chrome-192x192.png",
          body: event.data.notification.body,
        })
        if (location.pathname === "/campaigns") {
          dispatch(resetPageIndex())
        }
        notification.onclick = () => {
          event.preventDefault()
          window.open(`${process.env.REACT_APP_APP_URL}/campaigns`)
        }
      })
    })()
  }, [dispatch])

  useEffect(() => {
    messageEmitter.on('message', (payload) => {
      if (payload.data?.type === "RECEIVE_NEW_MESSAGE") {
        const messengerPayload = JSON.parse(payload?.data?.messengerPayload)
        dispatch(setNewMessengerPayload(messengerPayload))
        if (window.location.pathname !== '/messages') {
          toast.info(payload.notification.body, {
            onClick: () => navigate('/messages')
          })
        }
      } else {
        toast.info(payload.notification.body)
        if (window.location.pathname === "/campaigns") {
          dispatch(resetPageIndex())
        }
      }
    })
  }, [dispatch, navigate])

useEffect(() => {
  ;(async () => {
    if (fcmToken && currentUser) {
      await updateProfile({...currentUser, fcmToken})
    }
  })()
}, [currentUser, fcmToken])

useEffect(() => {
  if (currentUser) {
    countWaitingCampaign()
    countPendingTransaction()
    countConfirmingOrder()
    countNumberOfUnverifiedAccount()
  }
}, [
  countPendingTransaction,
  countWaitingCampaign,
  countConfirmingOrder,
  currentUser,
  countNumberOfUnverifiedAccount,
])

useEffect(() => {
  if (i18n.language === "vi") {
    import(`dayjs/locale/vi`).then(() => dayjs.locale("vi"))
  } else {
    import(`dayjs/locale/en-gb`).then(() => dayjs.locale("en-gb"))
  }
}, [i18n.language])

if (!currentUser) {
  return (
    <AuthLayout>
      <AuthRoutes/>
      <ToastContainer/>
      {/* <ReactTooltip /> */}
    </AuthLayout>
  )
}

return (
  <MainLayout key={i18n.language}>
    <MainRoutes/>
    <ToastContainer/>
  </MainLayout>
)
}

export default App
