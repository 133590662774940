import axios from "../axios"

export const getListOrders = (params) => {
  return axios.get(`/orders/`, { params })
}

export const getOrderById = (id) => {
  return axios.get(`/orders/${id}`)
}

export const uploadOrderContract = (orderId, payload) => {
  return axios.post(`/orders/${orderId}/contract`, payload)
}

export const finishUploadContract = (orderId) => {
  return axios.put(`/orders/${orderId}/contract`)
}

export const updateOrderStatus = (orderId, payload) => {
  return axios.put(`/orders/${orderId}/status`, payload)
}

export const updateOrderPaymentPlan = (orderId, payload) => {
  return axios.post(`/orders/${orderId}/paymentPlan`, payload)
}
