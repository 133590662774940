import { useMemo } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Icon from "components/Icon"
import { toCapitalizeFirstLetter } from "../../utils/string"

const SidebarItem = ({ item }) => {
  const { t } = useTranslation()
  let location = useLocation()
  const numberOfUnverifiedAccount = useSelector((state) => state.user.numberOfUnverifiedAccount)
  const numberOfWaitingCampaign = useSelector((state) => state.order.numberOfWaitingCampaign)
  const numberOfConfirmingOrder = useSelector((state) => state.order.numberOfConfirmingOrder)
  const numberOfPendingTransaction = useSelector(
    (state) => state.transaction.numberOfPendingTransaction
  )

  const isActive = useMemo(
    () =>
      (item.url === "/" ? location?.pathname === "/" : location?.pathname?.startsWith(item.url)) ||
      item?.subItems?.some((subItem) => location?.pathname === subItem.url),
    [item?.subItems, item.url, location?.pathname]
  )

  const badgeValue = useMemo(() => {
    if (item.id === "campaigns") return numberOfWaitingCampaign
    if (item.id === "transactions") return numberOfPendingTransaction
    if (item.id === "orders") return numberOfConfirmingOrder
    if (item.id === "customers") return numberOfUnverifiedAccount
  }, [
    item.id,
    numberOfWaitingCampaign,
    numberOfPendingTransaction,
    numberOfConfirmingOrder,
    numberOfUnverifiedAccount,
  ])

  return (
    <>
      <Link
        to={item.url}
        className={classNames(
          "relative flex items-center justify-between gap-x-4 pl-6 last:pb-10",
          {
            "before:content-[''] before:absolute before:left-0 before:bg-green2 before:w-2 before:h-6 before:rounded-r":
              isActive,
          }
        )}
      >
        <div className={classNames("flex items-center gap-x-4", { "opacity-30": !isActive })}>
          <Icon
            name={`${item.icon}${isActive ? "-active" : ""}`}
            width={24}
            height={24}
            className={classNames({
              "fill-primary": isActive,
              "fill-secondary": !isActive,
            })}
          />
          <span
            className={classNames("text-14 font-bold", {
              "text-primary": isActive,
              "text-secondary": !isActive,
            })}
          >
            {toCapitalizeFirstLetter(t(item.name))}
          </span>
        </div>
        {item.showBadge && (
          <span className="bg-red text-white font-bold text-14 rounded-md px-2">{badgeValue}</span>
        )}
      </Link>
      {isActive &&
        item?.subItems?.map((subItem, index) => {
          const isActiveItem = location?.pathname === subItem.url

          return (
            <Link
              key={index}
              to={subItem.url}
              className={classNames("relative flex items-center gap-x-4 pl-16 last:pb-10", {
                "opacity-30": !isActiveItem,
              })}
            >
              <span
                className={classNames("text-14 font-bold", {
                  "text-primary": isActiveItem,
                  "text-secondary": !isActiveItem,
                })}
              >
                {toCapitalizeFirstLetter(t(subItem.name))}
              </span>
            </Link>
          )
        })}
    </>
  )
}

export default SidebarItem
