import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    fcmToken: null,
    staffRoles: [],
    numberOfUnverifiedAccount: 10,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload
    },
    setStaffRoles: (state, action) => {
      state.staffRoles = action.payload || []
    },
    setNumberOfUnverifiedAccount: (state, action) => {
      state.numberOfUnverifiedAccount = action.payload
    },
  },
})

export const {
  reducer: userReducer,
  actions: { setCurrentUser, setFcmToken, setStaffRoles, setNumberOfUnverifiedAccount },
} = userSlice
