import { createSlice } from "@reduxjs/toolkit"

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    notificationsUnread: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload
    },
    setNotificationsUnread: (state, action) => {
      state.notificationsUnread = action.payload
    },
    setNotificationRead: (state, action) => {
      const notificationSelectedId = action.payload
      state.notificationsUnread = state.notificationsUnread.filter(
        (notification) => notification.data.notificationId !== notificationSelectedId
      )
    },
    setAllNotificationsRead: (state) => {
      state.notificationsUnread = []
    },
  },
})

export const {
  reducer: notificationReducer,
  actions: {
    setNotifications,
    setNotificationsUnread,
    setNotificationRead,
    setAllNotificationsRead,
  },
} = notificationSlice
