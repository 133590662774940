export const SIDEBAR_ITEMS = [
  {
    id: "dashboard",
    name: "dashboard",
    icon: "sidebar/dashboard",
    url: "/",
  },
  {
    id: "customers",
    name: "customer",
    icon: "sidebar/customer",
    url: "/customers",
    showBadge: true,
  },
  {
    id: "campaigns",
    name: "campaigns",
    icon: "sidebar/campaigns",
    url: "/campaigns",
    showBadge: true,
  },
  {
    id: "orders",
    name: "orders",
    icon: "sidebar/orders",
    url: "/orders",
    showBadge: true,
  },
  {
    id: "products",
    name: "products",
    icon: "sidebar/products",
    url: "/products",
    subItems: [{ name: "productSettings", url: "/product-settings" }],
  },
  {
    id: "banner",
    name: "banner",
    icon: "sidebar/banner",
    url: "/banner",
  },
  {
    id: "transactions",
    name: "transactions",
    icon: "sidebar/transactions",
    url: "/transactions",
    showBadge: true,
  },
  {
    id: "messages",
    name: "messages",
    icon: "sidebar/messages",
    url: "/messages",
    showBadge: false,
  },
  {
    id: "reports",
    name: "reports",
    icon: "sidebar/reports",
    url: "/reports",
    subItems: [
      { name: "revenue", url: "/reports/revenue" },
      { name: "growth", url: "/reports/growth" },
      { name: "debts", url: "/reports/debts" },
      { name: "products", url: "/reports/products" },
      { name: "customers", url: "/reports/customers" },
      // { name: "usePoints", url: "/reports/use-point" },
    ],
  },
  {
    id: "staffs",
    name: "staffs",
    icon: "sidebar/staffs",
    url: "/staffs",
  },
  {
    id: "content",
    name: "content",
    icon: "sidebar/website-content",
    url: "/content",
    subItems: [
      // { name: "about", url: "/about" },
      // { name: "privacy", url: "/privacy" },
      // { name: "terms", url: "/terms" },
      { name: "faqs", url: "/faqs" },
    ],
  },
  {
    id: "settings",
    name: "settings",
    icon: "sidebar/settings",
    url: "/settings",
    subItems: [
      { name: "configuration", url: "/configuration" },
      { name: "roles", url: "/roles" },
      { name: "membership", url: "/membership" },
      { name: "currencyExchangeRate", url: "/exchange-rates" },
    ],
  },
  {
    id: "promotions",
    name: "promotions",
    icon: "sidebar/promotions",
    url: "/promotions",
  },
  {
    id: "notifications",
    name: "notifications",
    icon: "notification",
    url: "/notifications",
  },
]
