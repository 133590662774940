import axios from "../axios"

export const getListRoles = (params) => {
  return axios.get(`/admin/staff-roles`, { params })
}

export const updateRole = (id, payload) => {
  return axios.put(`/admin/staff-roles/${id}`, payload)
}

export const createNewRole = (payload) => {
  return axios.post("/admin/staff-roles", payload)
}

export const deleteRole = (id) => {
  return axios.delete(`/admin/staff-roles/${id}`)
}
