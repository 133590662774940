import { createSlice } from '@reduxjs/toolkit'

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setCommonLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  reducer: commonReducer,
  actions: { setCommonLoading },
} = commonSlice
