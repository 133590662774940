import axios from "../axios"

export const getListTransactions = (params) => {
  return axios.get(`/admin/transactions/`, { params })
}

export const createTransaction = (payload) => {
  return axios.post(`/admin/transactions/`, payload)
}

export const updateTransactionStatus = (id, status) => {
  return axios.put(`/admin/transactions/${id}/status`, { status })
}
