import { useEffect } from "react"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import jwt_decode from "jwt-decode"

import Sidebar from "components/Sidebar"
import { getMe } from "services/api/users"
import { setCurrentUser } from "slice/userSlice"
import { getErrorMessage } from "utils/error"
import { ACCESS_TOKEN } from "constants/Authentication"

const MainLayout = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector((state) => state.common.isLoading)

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem(ACCESS_TOKEN)
        const decodedToken = jwt_decode(token)
        if (!decodedToken?.roles?.includes("admin")) {
          throw new Error("You don't have permission to login")
        }
        const userRes = await getMe()
        dispatch(setCurrentUser(userRes.data))
      } catch (error) {
        toast.error(getErrorMessage(error))
        dispatch(setCurrentUser(null))
        navigate("/login")
      }
    })()
  }, [dispatch, navigate])

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-primary/90 z-40 flex items-center justify-center">
          <div className="loader" />
        </div>
      )}
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-x-hidden h-full flex flex-col">{children}</div>
      </div>
    </>
  )
}

export default MainLayout
