import { createSlice } from "@reduxjs/toolkit"

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    numberOfPendingTransaction: 0,
  },
  reducers: {
    setNumberOfPendingTransaction: (state, action) => {
      state.numberOfPendingTransaction = action.payload
    },
  },
})

export const {
  reducer: transactionReducer,
  actions: { setNumberOfPendingTransaction },
} = transactionSlice
