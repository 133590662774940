import axios from "../axios"

export const getMe = () => {
  return axios.get("/user/profile")
}

export const updateProfile = (payload) => {
  return axios.put(`/user/profile`, payload)
}

export const getListUsers = (params) => {
  return axios.get(`/admin/users/`, { params })
}

export const updateUser = (id, payload) => {
  return axios.put(`/admin/users/${id}`, payload)
}

export const createNewUser = (payload) => {
  return axios.post("/admin/users/", payload)
}

export const login = (payload) => {
  return axios.post("/auth/login", payload)
}

export const getUserById = (id) => {
  return axios.get(`/admin/users/${id}`)
}

export const changePassword = (payload) => {
  return axios.post(`/user/profile/change-password`, payload)
}
