import { createSlice } from "@reduxjs/toolkit"

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    numberOfWaitingCampaign: 0,
    numberOfConfirmingOrder: 0,
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    setNumberOfWaitingCampaign: (state, action) => {
      state.numberOfWaitingCampaign = action.payload
    },
    setNumberOfConfirmingOrder: (state, action) => {
      state.numberOfConfirmingOrder = action.payload
    },
  },
})

export const {
  reducer: orderReducer,
  actions: { setOrders, setNumberOfWaitingCampaign, setNumberOfConfirmingOrder },
} = orderSlice
