import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

const Dashboard = React.lazy(() => import("pages/Dashboard"))

const Users = React.lazy(() => import("pages/Users"))
const CreateUser = React.lazy(() => import("pages/Users/UpsertUser"))
const EditUser = React.lazy(() => import("pages/Users/UpsertUser"))

const Orders = React.lazy(() => import("pages/Orders"))
const OrderDetail = React.lazy(() => import("pages/Orders"))

const Campaigns = React.lazy(() => import("pages/Campaigns"))
const EditCampaign = React.lazy(() => import("pages/Campaigns/UpsertCampaign"))
const CampaignDetail = React.lazy(() => import("pages/Campaigns"))

const Products = React.lazy(() => import("pages/Products"))
const CreateProduct = React.lazy(() => import("pages/Products/UpsertProduct"))
const EditProduct = React.lazy(() => import("pages/Products/UpsertProduct"))
const ProductSettings = React.lazy(() => import("pages/ProductSettings"))

const Transactions = React.lazy(() => import("pages/Transactions"))
const CreateTransaction = React.lazy(() => import("pages/Transactions/UpsertTransaction"))
const EditTransaction = React.lazy(() => import("pages/Transactions/UpsertTransaction"))

const Staffs = React.lazy(() => import("pages/Staffs"))
const CreateStaff = React.lazy(() => import("pages/Staffs/UpsertStaff"))
const EditStaff = React.lazy(() => import("pages/Staffs/UpsertStaff"))

const Banner = React.lazy(() => import("pages/Banner"))
const WebsiteContent = React.lazy(() => import("pages/WebsiteContent"))
const Settings = React.lazy(() => import("pages/Settings"))
const Configuration = React.lazy(() => import("pages/Settings/Configuration"))
const Roles = React.lazy(() => import("pages/Settings/Roles"))
const Membership = React.lazy(() => import("pages/Settings/Membership"))
const ExchangeRates = React.lazy(() => import("pages/Settings/ExchangeRates"))
const Promotions = React.lazy(() => import("pages/Promotions"))
const UpsertPromotion = React.lazy(() => import("pages/Promotions/UpsertPromotion"))
const About = React.lazy(() => import("pages/WebsiteContent/About"))
const Privacy = React.lazy(() => import("pages/WebsiteContent/Privacy"))
const Terms = React.lazy(() => import("pages/WebsiteContent/Terms"))
const FAQs = React.lazy(() => import("pages/WebsiteContent/FAQs"))
const Notifications = React.lazy(() => import("pages/Notifications"))
const UpsertNotification = React.lazy(() => import("pages/Notifications/UpsertNotification"))
const Theme = React.lazy(() => import("pages/Theme"))
const Messages = React.lazy(() => import("pages/Messages"))
const Reports = React.lazy(() => import("pages/Reports"))
const RevenueReport = React.lazy(() => import("pages/Reports/RevenueReport"))
const GrowthReport = React.lazy(() => import("pages/Reports/GrowthReport"))
const DebtsReport = React.lazy(() => import("pages/Reports/DebtsReport"))
const ProductsReport = React.lazy(() => import("pages/Reports/ProductsReport"))
const CustomersReport = React.lazy(() => import("pages/Reports/CustomersReport"))

const MainRoutes = () => {
  return (
    <React.Suspense fallback={<span>Loading</span>}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/customers" element={<Users />} />
        <Route path="/customers/create" element={<CreateUser />} />
        <Route path="/customers/:id/edit" element={<EditUser />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campaigns/:id/edit" element={<EditCampaign />} />
        <Route path="/campaigns/:refId" element={<CampaignDetail />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:refId" element={<OrderDetail />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/create" element={<CreateProduct />} />
        <Route path="/products/:id/edit" element={<EditProduct />} />
        <Route path="/product-settings" element={<ProductSettings />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transactions/create" element={<CreateTransaction />} />
        <Route path="/transactions/:id/edit" element={<EditTransaction />} />
        <Route path="/content" element={<WebsiteContent />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/staffs" element={<Staffs />} />
        <Route path="/staffs/create" element={<CreateStaff />} />
        <Route path="/staffs/:id/edit" element={<EditStaff />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/exchange-rates" element={<ExchangeRates />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/promotions/create" element={<UpsertPromotion />} />
        <Route path="/promotions/:id/edit" element={<UpsertPromotion />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/notifications/create" element={<UpsertNotification />} />
        <Route path="/notifications/:id/edit" element={<UpsertNotification />} />
        <Route path="/theme" element={<Theme />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/reports/revenue" element={<RevenueReport />} />
        <Route path="/reports/growth" element={<GrowthReport />} />
        <Route path="/reports/debts" element={<DebtsReport />} />
        <Route path="/reports/products" element={<ProductsReport />} />
        <Route path="/reports/customers" element={<CustomersReport />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
